import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { nextI18nLocales } from '@peloton/internationalize/models/nextI18n';
import { useOneTrust } from '@peloton/onetrust/useOneTrust';
import { useMicroCopy } from '@content/client/microCopy';
import usePrivacyPolicy, {
  usePolicyDocuments,
} from '@content/client/www/privacy-policy-updates/usePrivacyPolicy';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';
import { useLocale } from '../../hooks/useLocale';
import UpdateNotice from './UpdateNotice';
import { updatedSinceLastVisit } from './utils';

const UPDATED_POLICIES_KEY = 'peloton-updated-policies-key';

const PolicyScript: React.FC = () => {
  const locale = useLocale();
  const [dateViewed, setDateViewed] = useLocalStorage(UPDATED_POLICIES_KEY, null);
  const policyUpdatesHeader = useMicroCopy('policyUpdatesHeader');
  const { content, isLoading } = usePrivacyPolicy(locale);
  const { documents } = usePolicyDocuments(content);
  const [isActive, setIsActive] = useState(false);
  const { getConsentModelType, insertHTML, getIsAlertBoxClosed } = useOneTrust();
  const cookieContainer =
    typeof window !== 'undefined' && document.getElementById('onetrust-banner-sdk');
  const innerCookieContainer =
    typeof window !== 'undefined' &&
    (document.getElementsByClassName(
      'ot-sdk-container',
    ) as HTMLCollectionOf<HTMLElement>);
  useEffect(() => {
    if (cookieContainer && innerCookieContainer) {
      window.addEventListener('click', event => {
        const hasClickedCloseButton = (event.target as HTMLElement).classList[0];
        const hasClickedAcceptRejectButtons = (event.target as HTMLElement).parentElement
          ?.classList[0];
        if (
          hasClickedCloseButton === 'onetrust-close-btn-handler' ||
          hasClickedAcceptRejectButtons === 'banner-actions-container'
        ) {
          cookieContainer.style.cssText = 'display: unset; opacity: unset';
          innerCookieContainer[0].style.display = 'none';
        }
      });
    }
    const isOptOut = getConsentModelType() === 'opt-out';

    setIsActive(isOptOut && updatedSinceLastVisit(dateViewed, content?.updatedAt));
  }, [
    dateViewed,
    content?.updatedAt,
    getConsentModelType,
    getIsAlertBoxClosed,
    cookieContainer,
  ]);

  if (!content && isLoading) {
    return null;
  }

  const newPolicyDate = new Date(content.updatedAt);
  const formattedNewPolicyDate =
    newPolicyDate.toString().toLowerCase() !== 'invalid date'
      ? newPolicyDate.toLocaleDateString([...nextI18nLocales], {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : undefined;

  const updateNoticeElement = renderToStaticMarkup(
    <UpdateNotice
      header={policyUpdatesHeader}
      newPolicyDate={formattedNewPolicyDate}
      documents={documents}
      isCookieVisible={Boolean(cookieContainer)}
    />,
  );

  const closePolicyAndSetNewDate = () => {
    window.addEventListener('click', event => {
      const hasClickedCloseButton = (event.target as HTMLElement)?.dataset.testid;
      if (hasClickedCloseButton === 'close-icon') {
        document.querySelector('#policy-updates')?.remove();
        setDateViewed(content.updatedAt);
        setIsActive(false);
      }
    });
  };
  const isNoticeRendered =
    typeof window !== 'undefined' && Boolean(document.querySelector('#policy-updates'));
  if (isActive && !isNoticeRendered) {
    return (
      <>
        {documents.length > 0 && (
          <Script
            id="policy-update-script"
            data-test-id="policy-update-script"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
            ${insertHTML(
              updateNoticeElement,
              cookieContainer ? 'onetrust-banner-sdk' : 'onetrust-consent-sdk',
              closePolicyAndSetNewDate,
              {},
              // The below group ID will allow the element to be inserted when consent
              // is granted for Functional-category cookies
              'C0003',
            )}
          `,
            }}
          />
        )}
      </>
    );
  }

  return null;
};

export default PolicyScript;
