import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import React from 'react';

import { reportFocusMethod } from '@peloton/accessibility';
import UnsupportedBrowser from '@peloton/browser/UnsupportedBrowser';
import ChatStyles from '@peloton/drift/ChatStyles';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import ErrorHandler from '@peloton/next/components/ErrorHandler';
import Locales from '@peloton/next/components/Locales';
import Scripts from '@peloton/next/components/Scripts';
import type { AvailabilityByProduct } from '@ecomm/product-states/models/context';

// CookieSettinsOpen is a dynamic import because it depends on window.OneTrust
const CookieSettingsOpen = dynamic(
  () => import('@peloton/next/components/CookieSettingsOpen'),
  { ssr: false },
);
const Providers = dynamic(() => import('@peloton/next/components/Providers'));
export type Props = AppProps<{
  productState: AvailabilityByProduct;
  fallback: any;
  buildTime: number;
  locale: string;
}>;

export const App: React.FC<React.PropsWithChildren<Props>> = ({
  Component,
  pageProps: { fallback, productState, buildTime, locale, ...props },
}) => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      reportFocusMethod(document);
    }
  }, []);

  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  return (
    <>
      <UnsupportedBrowser />
      <ChatStyles />
      <Providers
        buildTime={buildTime}
        fallback={fallback}
        productState={productState}
        locale={locale}
      >
        <Scripts />
        <Script
          src="/appsFlyerSmartScript.js"
          id="appsflyer-js"
          onError={e => reportError(e, { script: '/appsFlyerSmartScript.js' })}
        />
        <Locales />
        <CookieSettingsOpen />
        <ErrorHandler>
          <div id="download-app-banner" />
          <Component {...props} />
        </ErrorHandler>
      </Providers>
    </>
  );
};
