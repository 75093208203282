import { grey, Link, FullWidthText } from '@pelotoncycle/design-system';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isIE11OrBelow } from './identify';

const browserUrls = [
  {
    name: 'Chrome',
    url: 'https://www.google.com/chrome/',
  },
  {
    name: 'Firefox',
    url: 'https://www.mozilla.org/en-US/firefox/new/',
  },
  {
    name: 'Safari',
    URL: 'https://www.apple.com/safari/',
  },
  {
    name: 'Edge',
    url: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
  },
];

const UnsupportedBrowser: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [unsupportedBrowser, setUnsupportedBrowser] = useState(false);
  useEffect(() => {
    if (isIE11OrBelow()) {
      setUnsupportedBrowser(true);
    }
  }, []);

  return (
    <>
      <noscript> You need to enable JavaScript to run this app. </noscript>
      {unsupportedBrowser && (
        <Container id="ie11">
          <Banner>
            <svg fill="white" role="img" viewBox="0 0 156 46">
              <path d="M27.04 11.065l3.31-5.745A3.515 3.515 0 0 0 29.067.522a3.504 3.504 0 0 0-4.79 1.287l-3.31 5.743c-7.055-2.227-14.952.655-18.804 7.337A16.143 16.143 0 0 0 .908 28.305l6.113-10.606a10.456 10.456 0 0 1 6.387-4.91 10.436 10.436 0 0 1 7.982 1.053c5.023 2.905 6.75 9.362 3.85 14.392-2.9 5.032-9.346 6.762-14.368 3.857l3.506-6.083a3.503 3.503 0 0 0 4.79-1.285 3.516 3.516 0 0 0-1.284-4.797 3.504 3.504 0 0 0-4.79 1.285L1.91 40.614a3.515 3.515 0 0 0 1.283 4.797 3.502 3.502 0 0 0 4.79-1.286l3.311-5.743c7.054 2.227 14.952-.655 18.804-7.338 3.852-6.682 2.395-14.974-3.059-19.98M47.227 22.83c1.567 0 2.565-.705 2.565-2.411 0-1.728-1.044-2.386-2.565-2.386h-2.158v4.796h2.158zm-5.336-7.596h5.426c3.156 0 5.745 1.569 5.745 5.185 0 3.616-2.611 5.21-5.745 5.21H45.07V30.7h-3.18V15.234zm15.84 0h10.217v2.799h-7.039v3.546h6.2v2.729h-6.2v3.595h7.358V30.7H57.73V15.234m15.886 0h3.179v12.669h6.994V30.7H73.616V15.234m26.437 7.732c0-2.887-1.907-5.094-4.722-5.094-2.817 0-4.723 2.207-4.723 5.094 0 2.89 1.906 5.096 4.723 5.096 2.815 0 4.722-2.207 4.722-5.096zm-12.715 0c0-4.57 3.542-7.937 7.993-7.937 4.45 0 7.99 3.367 7.99 7.937 0 4.572-3.54 7.94-7.99 7.94s-7.993-3.368-7.993-7.94zm23.814-4.933h-4.404v-2.8h11.988v2.8h-4.404V30.7h-3.18V18.033m23.704 4.933c0-2.887-1.907-5.094-4.722-5.094-2.817 0-4.724 2.207-4.724 5.094 0 2.89 1.907 5.096 4.724 5.096 2.815 0 4.722-2.207 4.722-5.096zm-12.716 0c0-4.57 3.543-7.937 7.994-7.937 4.45 0 7.992 3.367 7.992 7.937 0 4.572-3.542 7.94-7.992 7.94-4.451 0-7.994-3.368-7.994-7.94zm25.696.546c-.771-.727-1.84-2.115-1.84-2.115s.229 1.706.229 2.82v6.484h-2.975V15.097h.317l7.788 7.325c.75.704 1.816 2.113 1.816 2.113s-.203-1.75-.203-2.818v-6.483h2.974v15.602h-.316l-7.79-7.324" />
            </svg>
          </Banner>
          <FullWidthText
            backgroundColor={grey[40]}
            eyebrowText="SITE IS NOT OPTIMIZED FOR INTERNET EXPLORER"
            bodyText={
              <>
                This experience is optimized for{' '}
                {browserUrls.map(({ name, url }, index) => (
                  <>
                    {index > 0 && `, ${index === browserUrls.length - 1 ? 'and ' : ''}`}
                    <Link variant="body" size="large" href={url} key={index}>
                      {name}
                    </Link>
                  </>
                ))}{' '}
                browsers. <br />
                It looks like you&apos;re using Internet Explorer, please use another
                browser.
              </>
            }
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: block;
  height: 100%;
  background-color: ${grey[40]};
  postion: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  overflow: hidden;
  min-height: 100vh;
`;

const Banner = styled.div`
  width: 100%;
  height: 72px;
  padding: 18px 32px;
  color: white;
  background-color: #181a1d;

  svg {
    width: 120px;
    height: 36px;
  }
`;

export default UnsupportedBrowser;
