import React from 'react';
import { MONITORING_ENV } from '@peloton/app-config';
import { configureErrorHandler } from '@peloton/error-reporting';
import CodeOwnerErrorBoundary from '@peloton/error-reporting/CodeOwnerErrorBoundary';
import ErrorBoundary from '@peloton/error-reporting/ErrorBoundary';
import {
  consoleErrorReporter,
  datadogBrowserErrorReporter,
} from '@peloton/error-reporting/reporters';
import SimpleErrorPage from '@ecomm/layout/SimpleErrorPage';

const ErrorHandler: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const errorHandler = configureErrorHandler([
    consoleErrorReporter,
    ...(MONITORING_ENV !== 'local' ? [datadogBrowserErrorReporter] : []),
  ]);

  return (
    <ErrorBoundary
      renderError={() => (
        <div data-test-id="simple-error-page">
          <SimpleErrorPage />
        </div>
      )}
      reportError={errorHandler.reportError}
      errorContext={{ tags: { component: 'app' } }}
    >
      <CodeOwnerErrorBoundary>{children}</CodeOwnerErrorBoundary>
    </ErrorBoundary>
  );
};

export default ErrorHandler;
