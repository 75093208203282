import {
  ONE_TRUST_KEY_US,
  ONE_TRUST_KEY_DE,
  ONE_TRUST_KEY_AT,
  ONE_TRUST_KEY_AU,
  ONE_TRUST_KEY_CA,
  ONE_TRUST_KEY_GB,
  SEGMENT_KEY_AT,
  SEGMENT_KEY_AU,
  SEGMENT_KEY_CA,
  SEGMENT_KEY_DE,
  SEGMENT_KEY_GB,
  SEGMENT_KEY_US,
  STRIPE_ACCOUNT_KEY_AT,
  STRIPE_ACCOUNT_KEY_AU,
  STRIPE_ACCOUNT_KEY_CA,
  STRIPE_ACCOUNT_KEY_DE,
  STRIPE_ACCOUNT_KEY_GB,
} from '@peloton/app-config';
import { mapInternationalKeysByLocale } from '@peloton/internationalize/consolidatedAppConfigs/utils';
import type { Locale } from '@peloton/internationalize/models/locale';

const intlKeysObj: Record<string, string | undefined> = {
  ONE_TRUST_KEY_US,
  ONE_TRUST_KEY_DE,
  ONE_TRUST_KEY_AT,
  ONE_TRUST_KEY_CA,
  ONE_TRUST_KEY_AU,
  ONE_TRUST_KEY_GB,
  SEGMENT_KEY_AT,
  SEGMENT_KEY_AU,
  SEGMENT_KEY_CA,
  SEGMENT_KEY_DE,
  SEGMENT_KEY_GB,
  SEGMENT_KEY_US,
  STRIPE_ACCOUNT_KEY_AT,
  STRIPE_ACCOUNT_KEY_AU,
  STRIPE_ACCOUNT_KEY_CA,
  STRIPE_ACCOUNT_KEY_DE,
  STRIPE_ACCOUNT_KEY_GB,
  STRIPE_ACCOUNT_KEY_US: undefined,
};

type NormalizedKeys = {
  ONE_TRUST_KEY: string | undefined;
  SEGMENT_KEY: string | undefined;
  STRIPE_ACCOUNT_KEY: string | undefined;
};

export const useIntlKey = (locale: Locale): NormalizedKeys => {
  const normalizedKeys = mapInternationalKeysByLocale(locale, intlKeysObj);
  return normalizedKeys as NormalizedKeys;
};
