export const updatedSinceLastVisit = (
  oldTimeStamp: string | null,
  newTimeStamp: string,
) => {
  // No previous session exists; script should continue
  if (!oldTimeStamp) return true;
  const oldDate = new Date(oldTimeStamp).valueOf();
  if (Number.isNaN(oldDate)) return true;
  const newDate = new Date(newTimeStamp).valueOf();

  return oldDate < newDate;
};
