import { useRef, useEffect, useMemo } from 'react';
import { useTracking } from 'react-tracking';

export const useScrollDepthTracking = () => {
  const { trackEvent } = useTracking();
  const defaultValues = useMemo(
    () => ({
      pageHeight: 0,
      scrollDepth: 0,
      hasReachedBottom: false,
    }),
    [],
  );
  const scrollDepthRef = useRef(defaultValues);

  useEffect(() => {
    const scrollListener = () => {
      scrollDepthRef.current = {
        pageHeight: document.documentElement.scrollHeight,
        scrollDepth: Math.max(
          Math.round(window.scrollY),
          scrollDepthRef.current.scrollDepth,
        ),
        hasReachedBottom:
          Math.abs(
            scrollDepthRef.current.pageHeight -
              document.documentElement.clientHeight -
              scrollDepthRef.current.scrollDepth,
          ) <= 1,
      };
    };

    const beforeUnloadListener = () => {
      trackEvent({
        event: 'Page Scroll',
        properties: {
          scrollDepth: scrollDepthRef.current.scrollDepth,
          pageLength: scrollDepthRef.current.pageHeight,
          pageEnd: scrollDepthRef.current.hasReachedBottom ? 1 : 0,
        },
      });
      scrollDepthRef.current = defaultValues;
    };

    document.addEventListener('scroll', scrollListener);
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      document.removeEventListener('scroll', scrollListener);
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [defaultValues, trackEvent]);
};
