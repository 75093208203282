import dynamic from 'next/dynamic';
import Script from 'next/script';
import React, { useState } from 'react';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';

const GeoModal = dynamic(() =>
  import('@ecomm/bootstrapping/internationalize/GeoModalV2/GeoModalV2').then(
    mod => mod.GeoModalV2,
  ),
);

const Locales: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [localeLoaded, setLocaleLoaded] = useState(false);
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  const onLoad = () => {
    setLocaleLoaded(true);
  };

  return (
    <>
      <Script
        src="/locale.js"
        onLoad={onLoad}
        onError={e => reportError(e, { script: 'locale.js' })}
      />
      {localeLoaded && <GeoModal />}
    </>
  );
};

export default Locales;
