import { useRouter } from 'next/router';
import type { NextRouter } from 'next/router';
import React from 'react';
import type { I18nSupportType } from '@peloton/internationalize';
import { Locale } from '@peloton/internationalize';
import {
  LocaleContext,
  withLocale as _withLocale,
} from '@peloton/internationalize/locales';
import type { LocaleProviderProps } from '@peloton/internationalize/locales';

export const LocaleProvider: React.FC<React.PropsWithChildren<LocaleProviderProps>> = ({
  children,
  envLocale,
}) => {
  const router: NextRouter = useRouter();

  return (
    <LocaleContext.Provider
      value={{
        locale: (router?.locale ||
          envLocale ||
          router?.defaultLocale ||
          Locale.Default) as Locale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const withLocale = <T extends {}>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
  envSource: I18nSupportType,
): React.ComponentType<React.PropsWithChildren<T>> => {
  return _withLocale(Component, LocaleProvider, envSource);
};
