import { Container } from '@pelotoncycle/design-system';
import * as snippet from '@segment/snippet';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { identify } from '@peloton/analytics/analytics';
import {
  DRIFT_KEY,
  ADA_BOT_HANDLE,
  ADA_BOT_HANDLE_GENERATIVE,
} from '@peloton/app-config';
import { isRobot } from '@peloton/browser';
import {
  ADA_SETTINGS_SCRIPT_ID,
  toAdaScriptProps,
  toAdaSettingsString,
  addOpenChatCallbackToWindow,
  useDriftEventTracking,
} from '@peloton/chat';
import { ErrorBoundary, useErrorReporter } from '@peloton/error-reporting';
import { useScrollDepthTracking } from '@ecomm/analytics/hooks/useScrollDepthTracking';
import { useIsAdaMigrationEnabled } from '@ecomm/feature-toggle/AdaMigration';
import { useIsOriginalActive } from '@ecomm/feature-toggle/CocoEntryPoint';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useIntlKey } from '../hooks/useIntlKey';
import { useLocale } from '../hooks/useLocale';
import PrivacyPolicyScript from '../scripts/PolicyDocuments/PolicyUpdates';

function renderSegmentSnippet(apiKey: string) {
  const opts = {
    apiKey,
  };

  return snippet.min(opts);
}

function addTtclidToSessionStorage() {
  const ttclid = new URLSearchParams(window.location.search).get('ttclid');
  if (!sessionStorage.getItem('ttclid') || sessionStorage.getItem('ttclid') === '') {
    ttclid && sessionStorage.setItem('ttclid', ttclid);
  }
}

const Scripts: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    errorReporter: { reportError },
  } = useErrorReporter();
  const locale = useLocale();
  const { SEGMENT_KEY } = useIntlKey(locale);
  const isPolicyUpdatesToggleActive = useIsToggleActive()('updatedPrivacyNotice');
  useEffect(() => {
    // remove load abandonment tracking if it exists for Segment
    if ((window as any).__trackAbandons) {
      document.removeEventListener('visibilitychange', (window as any).__trackAbandons);
    }
    // remove load abandonment tracking if it exists for DataDog
    if ((window as any).__trackDDAbandons) {
      document.removeEventListener('visibilitychange', (window as any).__trackDDAbandons);
    }
    // identify bot traffic
    identify({ isBot: isRobot() } as any);
  }, []);

  const isAdaMigrationEnabled = useIsAdaMigrationEnabled();

  useEffect(() => {
    addOpenChatCallbackToWindow();
    addTtclidToSessionStorage();
  }, []);

  useDriftEventTracking();
  useScrollDepthTracking();
  const isInCocoOriginal = useIsOriginalActive();

  return (
    <>
      <Script
        id="segment-script"
        dangerouslySetInnerHTML={{ __html: renderSegmentSnippet(SEGMENT_KEY || '') }}
        onError={e => {
          reportError(e, { script: 'Segment' });
        }}
      />
      {ADA_BOT_HANDLE_GENERATIVE && isInCocoOriginal === false && (
        <>
          <Script
            id={ADA_SETTINGS_SCRIPT_ID}
            dangerouslySetInnerHTML={{
              __html: toAdaSettingsString(locale),
            }}
          />
          <HideAdaChatButton />
          <Script {...toAdaScriptProps(ADA_BOT_HANDLE_GENERATIVE)} />
        </>
      )}
      {DRIFT_KEY && !isAdaMigrationEnabled && isInCocoOriginal && (
        <Script
          id="drift-chat-script"
          dangerouslySetInnerHTML={{
            __html: `
              !function() {
                var t = window.driftt = window.drift = window.driftt || [];
                if (!t.init) {
                  if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
                  t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
                  t.factory = function(e) {
                    return function() {
                      var n = Array.prototype.slice.call(arguments);
                      return n.unshift(e), t.push(n), t;
                    };
                  }, t.methods.forEach(function(e) {
                    t[e] = t.factory(e);
                  }), t.load = function(t) {
                    var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                    o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                    var i = document.getElementsByTagName("script")[0];
                    i.parentNode.insertBefore(o, i);
                  };
                }
              }();
              drift.SNIPPET_VERSION = '0.3.1';
              drift.config({
                locale: '${locale}'
              });
              drift.load('${DRIFT_KEY}');
            `,
          }}
        />
      )}
      {ADA_BOT_HANDLE && isAdaMigrationEnabled && isInCocoOriginal && (
        <>
          <Script
            id={ADA_SETTINGS_SCRIPT_ID}
            dangerouslySetInnerHTML={{
              __html: toAdaSettingsString(locale),
            }}
          />
          <Script {...toAdaScriptProps(ADA_BOT_HANDLE)} />
        </>
      )}
      {/* Why use the stripe script instead of the package?
      From Stripe's documentation:
      To best leverage Stripe’s advanced fraud functionality, include this script on every page, not just the checkout page.
      This allows Stripe to detect suspicious behavior that may be indicative of fraud as customers browse your website.
    */}

      <Script
        src="https://js.stripe.com/v3/"
        id="stripe-js"
        strategy="lazyOnload"
        data-ot-ignore
        onError={e => {
          reportError(e, { script: 'Stripe' });
        }}
      />
      {isPolicyUpdatesToggleActive && (
        <Container data-test-id="privacy-policy-container">
          <ErrorBoundary
            renderError={() => <></>}
            reportError={reportError}
            errorContext={{ tags: { component: 'document_notice' } }}
          >
            <PrivacyPolicyScript />
          </ErrorBoundary>
        </Container>
      )}
    </>
  );
};

export default Scripts;

const HideAdaChatButton = createGlobalStyle`
  #ada-button-frame {
    display: none;
  }
`;
